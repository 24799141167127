export const TRANSLATIONS_PT = {
  "occasion": "ocasião",
  "You don’t have permissions to take a photo": "Não tem autorização para tirar uma fotografia",
  "No internet connection": "Não há ligação à Internet",
  "Something went wrong": "Algo correu mal",
  "No services available": "Não há serviços disponíveis",
  "Password must be at least 8 characters": "A palavra-passe tem de ter pelo menos 8 caracteres",
  "Service registration is closed": "O registo de serviços está encerrado",
  "New password does not meet security requirements": "A nova palavra-passe não cumpre os requisitos de segurança",
  "You can't remove this temporal item": "Não é possível remover este item temporal",
  "add occasion": "adicionar ocasião",
  "Occasion": "Ocasião",
  "Optional": "Opcional",
  "Flight reopened": "Voo reaberto",
  "This field is mandatory": "Este campo é obrigatório",
  "success": "sucesso",
  "Flight registration is closed": "O registo do voo foi encerrado",
  "validating": "validação",
  "reopen flight": "reabrir o voo",
  "close the flight": "fechar o voo",
  "finalization": "finalização",
  "approved": "aprovado",
  "validation": "validação",
  "failed": "reprovado",
  "Photo registration": "Registo de fotografias",
  "In order to close this flight you need to provide": "Para fechar este voo, é necessário fornecer",
  "Photo types required": "Tipos de fotos necessários",
  "Something wrong": "Algo errado",
  "Receive job vacancies": "Receber vagas de emprego",
  "Job vacancy": "Vaga de emprego",
  "Go to flights": "Ir para os voos",
  "Page not found": "Página não encontrada",
  "Please click here to proceed to flights list": "Clique aqui para aceder à lista de voos",
  "Your session has expired. Please sign in again.": "A sua sessão expirou. Por favor, inicie sessão novamente.",
  "Timestamps type": "Tipo de carimbo de data/hora",
  "You were signed out automatically": "Foi desconectado automaticamente",
  "Are you sure you want to sign out?": "Tem a certeza de que pretende terminar a sessão?",
  "is signed in": "está conectado",
  "Sign in": "Iniciar sessão",
  "Registration closed": "Registo fechado",
  "Close registration": "Fechar o registo",
  "Dummy Type": "Tipo de manequim",
  "Aircraft Damage": "Danos em aeronaves",
  "No types here yet": "Ainda não há tipos aqui",
  "Select type": "Selecionar tipo",
  "Please briefly describe where you found the item": "Por favor, descreva brevemente onde encontrou o objeto",
  "type": "tipo",
  "Describe location": "Descrever a localização",
  "Please pick on scheme": "Por favor, escolha um esquema",
  "Cones": "Cones",
  "Chocks": "Calços",
  "Cannot set a future time.": "Não é possível definir uma hora futura.",
  "This section is used to deal with visible damage to the airplane": "Esta secção é utilizada para lidar com danos visíveis no avião",
  "Mark location": "Marcar o local",
  "The end time must be later than the start time.": "A hora de fim deve ser posterior à hora de início.",
  "Future time is not allowed": "A hora futura não é permitida",
  "or": "ou",
  "No timestamps configured for this flight type": "Não há carimbos de hora configurados para este tipo de voo",
  "Set time": "Definir hora",
  "Confirm remove?": "Confirmar remoção?",
  "Change": "Alterar",
  "Change password?": "Alterar palavra-passe?",
  "My profile": "O meu perfil",
  "Don’t use phone while driving!": "Não utilizar o telemóvel durante a condução!",
  "Destination": "Destino",
  "Origin": "Origem",
  "filter group not found": "grupo de filtros não encontrado",
  "Date should not be before start date": "A data não deve ser anterior à data de início",
  "Could not save data, please try again later": "Não foi possível guardar os dados, tente novamente mais tarde",
  "The current password you've entered is incorrect": "A palavra-passe atual que introduziu está incorreta",
  "no user data": "sem dados do utilizador",
  "Helpdesk": "Serviço de assistência",
  "SENT": "ENVIADO",
  "DENIED": "NEGADO",
  "DECLINED": "RECUSADO",
  "CONFIRMED": "CONFIRMADO",
  "ACCEPTED": "ACEITE",
  "Set to now": "Definido para agora",
  "Set": "Definir",
  "Write your comment here, then press the green check mark": "Escreva aqui o seu comentário e, em seguida, prima a marca de verificação verde",
  "write new comment": "escrever novo comentário",
  "Short remark": "Comentário curto",
  "Filter group has not been updated": "O grupo de filtros não foi atualizado",
  "Save service registrations problem": "Gravar problema de registo de serviços",
  "and/or": "e/ou",
  "Input a brief description here": "Introduzir aqui uma breve descrição",
  "Right Tail Wing": "Asa da cauda direita",
  "Left Tail Wing": "Asa da cauda esquerda",
  "Tail": "Cauda",
  "Body": "Corpo",
  "Neck": "Pescoço",
  "Nose": "Nariz",
  "Right Engine": "Motor direito",
  "Left Engine": "Motor esquerdo",
  "Right Wing": "Asa direita",
  "Left Wing": "Asa esquerda",
  "next": "seguinte",
  "Lost item": "Item perdido",
  "Damage": "Danos",
  "Filter group": "Filtrar grupo",
  "Archived": "Arquivado",
  "Message archived": "Mensagem arquivada",
  "Message unarchived": "Mensagem não arquivada",
  "No": "Não",
  "Yes": "Sim",
  "to": "para",
  "Date range": "Intervalo de datas",
  "No flight details information": "Nenhuma informação sobre os detalhes do voo",
  "No messages yet": "Ainda não há mensagens",
  "messages yet": "mensagens ainda",
  "Flight not found": "Voo não encontrado",
  "from": "de",
  "From": "De",
  "Until": "Até",
  "Search": "Pesquisa",
  "registration complete": "registo completo",
  "Flight number/registration": "Número de voo/registo",
  "Attachment": "Anexo",
  "Airline": "Companhia aérea",
  "Username": "Nome de utilizador",
  "Password": "Palavra-passe",
  "Log in": "Iniciar sessão",
  "Open file": "Abrir ficheiro",
  "Unavailable": "Indisponível",
  "Read & Sign": "Ler e assinar",
  "Available": "Disponível",
  "Sign for understanding": "Assinar para compreensão",
  "You can check your APPI code on Sunrise portal.": "Pode consultar o seu código APPI no portal Sunrise.",
  "We strongly recommend saving it in your smartphone phonebook": "Recomendamos vivamente que o guarde na lista telefónica do seu smartphone",
  "The APPI code is required when resetting your Aviapartner": "O código APPI é necessário quando reiniciar o seu Aviapartner",
  "Extra shift request confirmed": "Pedido de turno extra confirmado",
  "This request is not valid anymore": "Este pedido já não é válido",
  "This message is marked as urgent": "Esta mensagem está marcada como urgente",
  "A new password should be different than the current one": "A nova palavra-passe deve ser diferente da atual",
  "Your password has expired. Please compose a new password and log in again.": "A sua palavra-passe expirou. Por favor, crie uma nova palavra-passe e inicie sessão novamente.",
  "Your password and confirmation password do not match": "A sua palavra-passe e a palavra-passe de confirmação não correspondem",
  "Your password must contain at least eight characters, at least one number and both lower and uppercase letters.": "A sua palavra-passe deve conter pelo menos oito caracteres, pelo menos um número e letras maiúsculas e minúsculas.",
  "The username or password you've entered is incorrect": "O nome de utilizador ou a palavra-passe que introduziu está incorreto",
  "Log out": "Terminar sessão",
  "Change password": "Alterar a palavra-passe",
  "Confirm new password": "Confirmar a nova palavra-passe",
  "New password": "Nova palavra-passe",
  "Current password": "Palavra-passe atual",
  "is logged in": "está registado",
  "Proceed to login": "Proceder ao login",
  "Your password has been changed successfully!": "A sua palavra-passe foi alterada com sucesso!",
  "Pick if its a damaged part or lost item": "Escolher se",
  "Pin on schema": "Pino no esquema",
  "Specify where did you found the item": "Especificar onde é que encontrou o artigo",
  "Include nearby area": "Incluir área próxima",
  "Add other location": "Adicionar outra localização",
  "Add row": "Adicionar linha",
  "Add line": "Adicionar linha",
  "Skip this step": "Saltar este passo",
  "Close": "Fechar",
  "Take a photo": "Tirar uma fotografia",
  "Add description": "Adicionar descrição",
  "Add location where item was found": "Adicionar local onde o item foi encontrado",
  "Add storage location": "Adicionar local de armazenamento",
  "Briefly describe where the item will be stored for picking up.": "Descreva brevemente o local onde o artigo será armazenado para recolha.",
  "area": "área",
  "save": "guardar",
  "FirebaseError: A document cannot be written because it exceeds the maximum size allowed": "FirebaseError: Um documento não pode ser escrito porque excede o tamanho máximo permitido",
  "Photos": "Fotos",
  "Messages": "Mensagens",
  "Remove": "Remover",
  "Are you sure you want to remove photo?": "Tem a certeza de que pretende remover a fotografia?",
  "Comment": "Comentário",
  "Included nearby area": "Área próxima incluída",
  "Tap on area where damaged part is located": "Toque na área onde se encontra a peça danificada",
  "Conditional services": "Serviços condicionais",
  "SYSTEM SETTINGS": "DEFINIÇÕES DO SISTEMA",
  "Flights": "Voos",
  "Weather": "Tempo",
  "Language": "Idioma",
  "Receive Extra shift requests": "Receber pedidos de turnos extra",
  "Default station": "Estação predefinida",
  "Profile data wasn't updated!": "Os dados do perfil não foram actualizados!",
  "Station": "Estação",
  "Sign out": "Sair",
  "Cancel": "Cancelar",
  "Stations": "Estações",
  "Profile": "Perfil da estação",
  "Next 5 days": "Próximos 5 dias",
  "Today": "Hoje",
  "TODAY": "HOJE",
  "Humidity": "Humidade",
  "Wind": "Vento",
  "Precipitation": "Precipitação",
  "Pressure": "Pressão",
  "Clouds": "Nuvens",
  "Visibility": "Visibilidade",
  "Sunrise": "Nascer do sol",
  "Flight details": "Detalhes do voo",
  "General info": "Informações gerais",
  "Parking": "Estacionamento",
  "Runway": "Pista de aterragem",
  "Gate": "Porta de embarque",
  "Aircraft type": "Tipo de aeronave",
  "Operation flight number": "Número do voo da operação",
  "Seats": "Lugares",
  "Seat": "Lugar",
  "Flight type": "Tipo de voo",
  "Registration": "Registo",
  "Call sign": "Indicativo de chamada",
  "Crew signature": "Assinatura da tripulação",
  "Extra services": "Serviços extra",
  "extra service": "serviço extra",
  "Comments": "Comentários",
  "Service registration": "Registo do serviço",
  "All": "Todos",
  "Signed": "Assinado",
  "Internal communication": "Comunicação interna",
  "Extra shift request": "Pedido de turno suplementar",
  "Extra Shift Request": "Pedido de turno extra",
  "Service info saved": "Informação de serviço guardada",
  "Time registration": "Registo de horas",
  "No instructions found": "Não foram encontradas instruções",
  "Instructions": "Instruções",
  "NOW": "AGORA",
  "Sunset": "Pôr do sol",
  "The app has been updated": "A aplicação foi actualizada",
  "End time should not be before start time": "A hora de fim não deve ser anterior à hora de início",
  "Start Time": "Hora de início",
  "Start": "Hora de início",
  "End Time": "Hora final",
  "End": "Fim",
  "Tomorrow": "Amanhã",
  "Yesterday": "Ontem",
  "No photo available": "Nenhuma foto disponível",
  "Show Photo": "Mostrar foto",
  "Walkaround Arrival Checklist": "Lista de controlo de chegada",
  "Inspections": "Inspecções",
  "Walkaround Pre-Departure Checklist": "Lista de controlo antes da partida",
  "Completed": "Concluída",
  "Not completed": "Não concluída",
  "Visual checks must be performed BEFORE GSE positioning": "As verificações visuais devem ser efectuadas ANTES do posicionamento do GSE",
  "Visual checks must be performed AFTER GSE removal": "As verificações visuais devem ser efectuadas APÓS a remoção do GSE",
  "Report any irregularities found upon arrival": "Comunicar qualquer irregularidade",
  "Report any other irregularities": "Comunicar quaisquer outras irregularidades",
  "If damage is found, mark it on the sketch": "Se forem detectados danos, assinalá-los no desenho",
  "Checks on easily visible parts of the aircraft from the ground": "Controlos das partes da aeronave facilmente visíveis do solo",
  "Please make sure you attached photos of irregularities": "Certifica-te de que anexas fotografias das irregularidades",
  "Add description if there is a irregularity!": "Adicione uma descrição se houver uma irregularidade!",
  "Sign form below to complete it": "Assinar o formulário abaixo para o completar",
  "This form is signed": "Este formulário está assinado",
  "Reopen": "Reabrir",
  "Add note": "Adicionar nota",
  "Hold Inspection on Arrival": "Suspender a inspeção à chegada",
  "Hold Inspection on Departure": "Aguardar inspeção à partida",
  "Flight information registration": "Registo de informações de voo",
  "Arrival MVT sent successfully": "MVT de chegada enviado com sucesso",
  "Arrival MVT sending": "MVT de chegada enviado",
  "Arrival MVT not sent yet": "MVT de chegada ainda não enviado",
  "Arrival MVT has been sent": "MVT de chegada foi enviado",
  "CONFIRM": "CONFIRMAR",
  "CANCEL": "CANCELAR",
  "Cannot send Arrival MVT": "Não é possível enviar o MVT de chegada",
  "SEND ARRIVAL MVT": "ENVIAR MVT DE CHEGADA",
  "SEND CORRECTION ARRIVAL MVT": "ENVIAR MVT DE CHEGADA DE CORRECÇÃO",
  "In order to send Arrival MVT you need to provide mandatory details": "Para enviar o MVT de Chegada é necessário fornecer os dados obrigatórios",
  "Send the Arrival MVT message to recipients:": "Enviar a mensagem MVT de Chegada aos destinatários:",
  "the MVT will be sent": "o MVT será enviado",
  "the correction MVT will be sent": "o MVT de correção será enviado",
  "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist": "Valor ALDT/AIBT inválido ou já existe um MVT de chegada com esses valores ALDT e AIBT",
  "Cannot create MVT message: missing flight information": "Não é possível criar uma mensagem MVT: falta informação de voo",
  "MVT History": "Histórico do MVT",
  "Timeline": "Linha de tempo",
  "No photos here yet": "Ainda não há fotos aqui",
  "Safety Ramp Checklist": "Lista de verificação da rampa de segurança",
  "required to close the flight": "necessário para fechar o voo",
  "Forms": "Formulários",
  "Message state": "Estado da mensagem",
  "Locked application": "Aplicação bloqueada",
  "Go to Unread R&S": "Ir para R&S não lidas",
  "Start anti-icing": "Iniciar anti-gelo",
  "Stop anti-icing": "Parar anti-gelo",
  "Deicing Service Registration": "Registo do serviço de degelo",
  "Type": "Tipo de produto",
  "Mix": "Mistura",
  "Quantity (liter)": "Quantidade (litro)",
  "Agent’s name:": "Nome do agente:",
  "Agent’s signature:": "Assinatura do agente:",
  "Client’s signature:": "Assinatura do cliente:",
  "You are offline": "Está offline",
  "additional services": "serviços adicionais",
  "Required": "Necessário",
  "Set date": "Definir data",
  "Day": "Dia",
  "Month": "Mês",
  "Year": "Ano",
  "Telex messages": "Mensagens telex",
  "Message type": "Tipo de mensagem",
  "APPLY": "APLICAR",
  "Filter": "Filtro",
  "Please, sign below": "Por favor, assine abaixo",
  "SAVE": "SALVAR",
  "CLEAR": "CLARA",
  "In order to open 'Calendar', read and process recent Read & Sign messages.": "Para abrir o “Calendário”, ler e processar as mensagens recentes de leitura e assinatura.",
  "Number of unread R&S:": "Número de R&S não lidas:",
  "The shift is already confirmed to": "A deslocação já está confirmada para os",
  "employees": "funcionários",
  "Name Surname": "Nome Apelido",
  "Anti-icing": "Anti-gelo",
  "Product type": "Tipo de produto",
  "Product name": "Nome do produto",
  "Temperature (C°)": "Temperatura (C°)",
  "Add deicing/anti-icing": "Adicionar degelo/anti-gelo",
  "Load Verification Cross-Check Form": "Formulário de controlo cruzado de verificação da carga",
  "The planning shown below might not be up-to-date": "O planeamento apresentado abaixo pode não estar atualizado",
  "Read": "Ler",
  "Unread": "Não lido",
  "The elapsed time between": "O tempo decorrido entre",
  "and": "e",
  "exceeds the allowed limit for this flight": "excede o limite permitido para este voo",
  "Cannot be before": "Não pode ser antes",
  "Cannot be after": "Não pode ser depois",
  "time": "tempo",
  "Is Partial": "é parcial",
  "Inspection agent’s signature": "Assinatura do agente de inspeção:",
  "Truck driver’s signature": "Assinatura do caminhoneiro:",
  "Executor’s signature:": "Assinatura do executor:",
  "Executor’s name:": "Nome do executor:",
  "Truck Number": "Número do caminhão",
  "Truck Driver": "Motorista de caminhão",
  "Inspected by": "Inspecionado por",
  "Outside Air Temperature": "Temperatura do ar externo (C°)",
};
