export const TRANSLATIONS_ES = {
  "occasion": "ocasión",
  "You don’t have permissions to take a photo": "No tienes permisos para hacer una foto",
  "No internet connection": "No hay conexión a Internet",
  "Something went wrong": "Algo salió mal",
  "No services available": "No hay servicios disponibles",
  "Password must be at least 8 characters": "La contraseña debe tener al menos 8 caracteres y contener al menos 1 letra mayúscula y 1 minúscula, 1 símbolo, 1 dígito y sin espacios en blanco.",
  "Service registration is closed": "El registro del servicio está cerrado.",
  "New password does not meet security requirements": "La nueva contraseña no cumple con los requisitos de seguridad",
  "You can't remove this temporal item": "No se puede eliminar este elemento temporal",
  "add occasion": "añadir ocasión",
  "Occasion": "Ocasión",
  "Optional": "Opcional",
  "Flight reopened": "Vuelo reabierto",
  "This field is mandatory": "Este campo es obligatorio",
  "success": "éxito",
  "Flight registration is closed": "El registro de vuelos está cerrado.",
  "validating": "validando",
  "reopen flight": "reabrir el vuelo",
  "close the flight": "cerrar el vuelo",
  "finalization": "finalización",
  "approved": "aprobado",
  "failed": "falló",
  "validation": "validación",
  "Photo registration": "Registro fotográfico",
  "In order to close this flight you need to provide": "Para cerrar este vuelo es necesario proporcionar los datos obligatorios.",
  "Photo types required": "Tipos de fotos requeridas",
  "Photo types required:": "Tipos de fotos requeridas:",
  "Something wrong": "Algo va mal",
  "or": "o",
  "Receive job vacancies": "Recibir ofertas de empleo",
  "Job vacancy": "Oferta de empleo",
  "Go to flights": "Ir a vuelos",
  "Page not found": "Página no encontrada",
  "Please click here to proceed to flights list": "Haga clic aquí para pasar a la lista de vuelos",
  "Your session has expired. Please sign in again.": "Su sesión ha expirado. Por favor, inicie sesión de nuevo.",
  "Timestamps type": "Tipo de sello de tiempo",
  "You were signed out automatically": "Se ha cerrado la sesión automáticamente. El token ha caducado. Puede volver a iniciar sesión con sus credenciales",
  "Are you sure you want to sign out?": "¿Estás seguro de que quieres cerrar la sesión?",
  "is signed in": "se firma en",
  "Sign in": "Acceder",
  "Registration closed": "Inscripción cerrada",
  "Close registration": "Cerrar el registro",
  "Dummy Type": "Tipo de maniquí",
  "Aircraft Damage": "Daños en la aeronave",
  "No types here yet": "No hay tipos aquí todavía",
  "Select type": "Seleccione el tipo",
  "Please briefly describe where you found the item": "Describa brevemente dónde encontró el artículo. Especifique la sala o la línea y la fila si está disponible",
  "type": "tipo",
  "Describe location": "Describa la ubicación",
  "Please pick on scheme": "Por favor, elija el esquema",
  "Cones": "Conos",
  "Chocks": "Choques",
  "Cannot set a future time.": "No se puede fijar una hora futura.",
  "This section is used to deal with visible damage to the airplane": "Esta sección se utiliza para tratar los daños visibles del avión. Por favor, documente todos los problemas visibles antes de realizar cualquier servicio",
  "Mark location": "Ubicación de la marca",
  "The end time must be later than the start time.": "La hora de finalización debe ser posterior a la hora de inicio.",
  "Future time is not allowed": "El tiempo futuro no está permitido",
  "No timestamps configured for this flight type": "No hay marcas de tiempo configuradas para este tipo de vuelo",
  "Set time": "Fijar la hora",
  "Confirm remove?": "¿Confirmar la eliminación?",
  "Change": "Cambiar",
  "Change password?": "¿Cambiar la contraseña?",
  "My profile": "Mi perfil",
  "Don’t use phone while driving!": "Prohibido usar el móvil mientras conduzcas!",
  "Destination": "Destino",
  "Origin": "Origen",
  "filter group not found": "grupo de filtrado no encontrado",
  "Date should not be before start date": "La fecha no debe ser anterior a la fecha de inicio",
  "Could not save data, please try again later": "No se han podido guardar los datos, por favor, inténtelo más tarde",
  "The current password you've entered is incorrect": "La contraseña que has introducido es incorrecta. Por favor, compruébala e inténtalo de nuevo.",
  "no user data": "sin datos del usuario",
  "Helpdesk": "Servicio de asistencia técnica",
  "SENT": "ENVIADO",
  "DENIED": "DENEGADO",
  "DECLINED": "DECLINADO",
  "CONFIRMED": "CONFIRMADO",
  "ACCEPTED": "ACEPTADO",
  "Set to now": "Ajustar a ahora",
  "Set": "Establecer",
  "Write your comment here, then press the green check mark": "Escriba su comentario aquí y pulse la marca de verificación verde",
  "write new comment": "escribir un nuevo comentario",
  "Short remark": "Breve comentario",
  "Filter group has not been updated": "El grupo de filtros no ha sido actualizado",
  "Save service registrations problem": "Guardar el problema de las inscripciones al servicio",
  "and/or": "y/o",
  "Input a brief description here": "Introduzca una breve descripción aquí",
  "Right Tail Wing": "Ala de cola derecha",
  "Left Tail Wing": "Ala de cola izquierda",
  "Tail": "Cola",
  "Body": "Cuerpo",
  "Neck": "Cuello",
  "Nose": "Nariz",
  "Right Engine": "Motor derecho",
  "Left Engine": "Motor izquierdo",
  "Right Wing": "Ala de la derecha",
  "Left Wing": "Ala izquierda",
  "next": "siguiente",
  "Lost item": "Objeto perdido",
  "Damage": "Daño",
  "Filter group": "Grupo de filtrado",
  "Archived": "Archivado",
  "Message archived": "Mensaje archivado",
  "Message unarchived": "Mensaje no archivado",
  "No": "No",
  "Yes": "Sí",
  "to": "a",
  "Date range": "Rango de fechas",
  "Telex messages": "Mensajes de télex",
  "No flight details information": "No hay información sobre los detalles del vuelo",
  "No messages yet": "Todavía no hay mensajes",
  "messages yet": "mensajes todavía",
  "Flight not found": "Vuelo no encontrado",
  "from": "de",
  "From": "De",
  "Until": "Bis",
  "Search": "Buscar en",
  "registration complete": "registro completo",
  "Flight number/registration": "Número de vuelo/registro",
  "Attachment": "Adjunto",
  "Airline": "Línea aérea",
  "Username": "Nombre de usuario",
  "Password": "Contraseña",
  "Log in": "Entrar en el sistema",
  "Open file": "Abrir archivo",
  "area": "zona",
  "Unavailable": "No disponible",
  "Read & Sign": "Leer y firmar",
  "Available": "Disponible",
  "Sign for understanding": "Signo de comprensión",
  "You can check your APPI code on Sunrise portal.": "Puede comprobar su código APPI en el portal Sunrise.",
  "We strongly recommend saving it in your smartphone phonebook": "Le recomendamos encarecidamente que lo guarde en la agenda de su smartphone para tener siempre a mano el código APPI. Para guardar el código, ve a tu perfil de Sunrise y escanea el código QR con tu smartphone.",
  "The APPI code is required when resetting your Aviapartner": "El código APPI es necesario para restablecer la contraseña de su cuenta Aviapartner en Sunrise o a través del servicio de asistencia de Aviapartner. Siempre podrá encontrar su código APPI en su perfil en Sunrise.",
  "Extra shift request confirmed": "Solicitud de turno extra confirmada",
  "This request is not valid anymore": "Esta solicitud ya no es válida",
  "This message is marked as urgent": "Este mensaje está marcado como urgente",
  "A new password should be different than the current one": "Una nueva contraseña debe ser diferente a la actual",
  "Your password has expired. Please compose a new password and log in again.": "Su contraseña ha caducado. Por favor, componga una nueva contraseña y conéctese de nuevo.",
  "Your password and confirmation password do not match": "Su contraseña y su contraseña de confirmación no coinciden.",
  "Your password must contain at least eight characters": "Su contraseña debe contener al menos ocho caracteres, al menos un número y letras minúsculas y mayúsculas ",
  "The username or password you've entered is incorrect": "El nombre de usuario o la contraseña que has introducido son incorrectos. Por favor, compruébalo e inténtalo de nuevo.",
  "Log out": "Cerrar la sesión",
  "Change password": "Cambiar contraseña",
  "Confirm new password": "Confirmar la nueva contraseña",
  "New password": "Nueva contraseña",
  "Current password": "Contraseña actual",
  "is logged in": "está conectado",
  "Proceed to login": "Proceder al inicio de sesión",
  "Your password has been changed successfully!": "Su contraseña ha sido cambiada con éxito.",
  "Pick if its a damaged part or lost item": "Elija si es una pieza dañada o un artículo perdido",
  "Pin on schema": "Pin en el esquema",
  "Specify where did you found the item": "Especifique dónde encontró el artículo",
  "Include nearby area": "Incluir la zona cercana",
  "Add other location": "Añadir otra ubicación",
  "Add line": "Añadir línea",
  "Add row": "Añadir fila",
  "Skip this step": "Omitir este paso",
  "Add description": "Añadir descripción",
  "Add location where item was found": "Añadir el lugar donde se encontró el artículo",
  "Add storage location": "Añadir lugar de almacenamiento",
  "Briefly describe where the item will be stored for picking up.": "Describa brevemente dónde se almacenará el artículo para su recogida.",
  "Take a photo": "Haz una foto",
  "Close": "Cerrar",
  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed": "FirebaseError: No se puede escribir un documento porque supera el tamaño máximo permitido",
  "Photos": "Fotos",
  "Messages": "Mensajes",
  "Remove": "Eliminar",
  "Are you sure you want to remove photo?": "¿Estás seguro de que quieres eliminar la foto?",
  "Comment": "Comentario",
  "Included nearby area": "Incluye la zona cercana",
  "Tap on area where damaged part is located": "Golpear la zona donde se encuentra la pieza dañada",
  "Conditional services": "Servicios condicionales",
  "SYSTEM SETTINGS": "Configuración del sistema",
  "Flights": "Vuelos",
  "Weather": "Tiempo",
  "Language": "Idioma",
  "Receive Extra shift requests": "Recibir solicitudes de turno extra",
  "Default station": "Estación predeterminada",
  "Profile data wasn't updated!": "Los datos del perfil no se actualizaron",
  "Station": "Estación",
  "Sign out": "Desconexión",
  "Cancel": "Cancelar",
  "Stations": "Estaciones",
  "Profile": "Perfil",
  "Next 5 days": "Próximos 5 días",
  "Today": "Hoy",
  "TODAY": "HOY",
  "Humidity": "Humedad",
  "Wind": "Viento",
  "Precipitation": "Precipitación",
  "Pressure": "Presión",
  "Clouds": "Nubes",
  "Visibility": "Visibilidad",
  "Sunrise": "Amanecer",
  "Flight details": "Detalles del vuelo",
  "General info": "Información general",
  "Parking": "Aparcamiento",
  "Runway": "Pista",
  "Gate": "Puerta",
  "Aircraft type": "Tipo de aeronave",
  "Operation flight number": "Número de vuelo de operación",
  "Seats": "Asientos",
  "Seat": "Asiento",
  "Flight type": "Tipo de vuelo",
  "Registration": "Matrícula",
  "Call sign": "Distintivo de llamada",
  "Crew signature": "FIRMA CREW",
  "Extra services": "Servicios extras",
  "extra service": "Servicio extra",
  "Comments": "Comentarios",
  "Service registration": "Registro de servicios",
  "Message type": "TIPO DE MENSAJE",
  "All": "Todos",
  "APPLY": "APLICA",
  "Filter": "Filtro",
  "Signed": "Firmado",
  "Internal communication": "Comunicación interna",
  "Extra shift request": "Solicitud de turno extra",
  "Extra Shift Request": "Solicitud de turno extra",
  "Please, sign below": "Por favor, firme abajo",
  "SAVE": "Guardar",
  "save": "guardar",
  "CLEAR": "Claro",
  "Service info saved": "Información de servicio guardada",
  "Time registration": "Registro de tiempo",
  "No instructions found": "No se encontraron instrucciones",
  "Instructions": "Instrucciones",
  "NOW": "AHORA",
  "Sunset": "Atardecer",
  "The app has been updated": "La aplicación se ha actualizado",
  "End time should not be before start time": "La hora de finalización no debe ser anterior a la hora de inicio",
  "Start Time": "Hora de inicio",
  "Start": "Hora",
  "End Time": "Fin de los tiempos",
  "End": "Fin",
  "Tomorrow": "Mañana",
  "Yesterday": "Ayer",
  "No photo available": "No hay fotos disponibles",
  "Show Photo": "Mostrar foto",
  "Walkaround Arrival Checklist": "Lista de verificación de llegada de caminata",
  "Inspections": "Inspecciones",
  "Walkaround Pre-Departure Checklist": "Lista de verificación previa a la partida de caminata",
  "Completed": "Completado",
  "Not completed": "No completado",
  "Visual checks must be performed BEFORE GSE positioning": "Las verificaciones visuales deben realizarse antes del posicionamiento de Equipos",
  "Visual checks must be performed AFTER GSE removal": "Las verificaciones visuales deben realizarse después de retirada de Equipos",
  "Report any irregularities found upon arrival": "Informar de cualquier irregularidad encontrada a la llegada",
  "Report any other irregularities": "Informar de cualquier otra irregularidad.",
  "If damage is found, mark it on the sketch": "Si se encuentran daños, marquéelo en el boceto.",
  "Checks on easily visible parts of the aircraft from the ground": "Verificaciones a partes fácilmente visibles de la aeronave desde tierra. Sin daños obvios o anomalias:",
  "Please make sure you attached photos of irregularities": "Asegúrese de adjuntar fotos de irregularidades",
  "Add description if there is a irregularity!": "¡Agregue la descripción si hay una irregularidad!",
  "Sign form below to complete it": "Firme a continuación para completar el formulario:",
  "This form is signed": "Este formulario ha sido firmado",
  "Reopen": "Reabrir",
  "Add note": "Añadir la nota...",
  "Hold Inspection on Arrival": "Inspección de bodega llegada",
  "Hold Inspection on Departure": "Inspección de bodega partida",
  "Flight information registration": "Registro de información de vuelo",
  "Arrival MVT sent successfully": "MVT de llegada enviada con éxito",
  "Arrival MVT sending": "Enviando MVT llegada",
  "Arrival MVT not sent yet": "MVT no enviado",
  "Arrival MVT has been sent": "MVT ha sido enviado",
  "CONFIRM": "CONFIRMAR",
  "CANCEL": "CANCELAR",
  "Cannot send Arrival MVT": "No se puede enviar MVT llegada. Las direcciones (SITA o/y email) no se han configurado en Sunrise.",
  "SEND ARRIVAL MVT": "ENVIAR MVT LLEGADA",
  "SEND CORRECTION ARRIVAL MVT": "ENVIAR CORRECCIÓN MVT LLEGADA",
  "In order to send Arrival MVT you need to provide mandatory details": "Para enviar el MVT llegada es necesario incluir los datos requeridos.",
  "Send the Arrival MVT message to recipients:": "Enviar MVT llegada a destinatarios:",
  "the MVT will be sent": "Después de hacer clic en 'CONFIRMAR', se enviará el MVT. Se puede volver a enviar si hay un cambio en ALDT o/y AIBT.",
  "the correction MVT will be sent": "Después de hacer clic en 'CONFIRMAR', se enviará el MVT de corrección. Se puede volver a enviar si hay un cambio en ALDT o/y AIBT.",
  "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist": "Ya existe un valor ALDT/AIBT no válido o un MVT de llegada con dichos valores ALDT y AIBT.",
  "Cannot create MVT message: missing flight information": "No se puede crear el mensaje MVT: falta información del vuelo",
  "MVT History": "Histórico MVT",
  "Timeline": "Cronología",
  "No photos here yet": "No hay fotos",
  "Safety Ramp Checklist": "Safety Ramp Checklist",
  "required to close the flight": "Requerido para cerrar el vuelo",
  "Forms": "Formularios",
  "Message state": "Estado del mensaje",
  "Locked application": "Aplicación bloqueada",
  "In order to open 'Calendar', read and process recent Read & Sign messages.": "Para abrir el 'Calendario', leer y procesar los mensajes recientes de Read & Sign.",
  "Number of unread R&S:": "Número de R&S sin leer:",
  "Go to Unread R&S": "Ir a R&S sin leer",
  "The shift is already confirmed to": "El turno ya está confirmado para",
  "employees": "empleados",
  "Name Surname": "Nombre Apellidos",
  "Start anti-icing": "Comienzo anti-icing",
  "Stop anti-icing": "Fin anti-icing",
  "Deicing Service Registration": "Registro del servicio de deshielo",
  "Anti-icing": "Anti-icing",
  "Type": "Tipo",
  "Product type": "Tipo producto",
  "Product name": "Nombre producto",
  "Mix": "Mezcla",
  "Temperature (C°)": "Temperatura (C°)",
  "Quantity (liter)": "Cantidad (litros)",
  "Add deicing/anti-icing": "Añadir deicing/anti-icing",
  "Agent’s name:": "Nombre del agente:",
  "Agent’s signature:": "Firma del agente:",
  "Client’s signature:": "Firma del cliente:",
  "Load Verification Cross-Check Form": "Formulario Cross-Check Verificación de Carga",
  "You are offline": "Estás desconectado.",
  "The planning shown below might not be up-to-date": "Es posible que la planificación que se muestra a continuación no esté actualizada.",
  "Read": "Leer",
  "Unread": "No leído",
  "The elapsed time between": "El tiempo transcurrido entre ",
  "and": " y ",
  "exceeds the allowed limit for this flight": " excede el límite permitido para este vuelo.",
  "Cannot be before": "No puede ser antes ",
  "Cannot be after": "No puede ser después ",
  "time": " tiempo.",
  "Required": "Requerido",
  "Set date": "Establecer fecha",
  "additional services": "Servicios adicionales",
  "Day": "Día",
  "Month": "Mes",
  "Year": "Año",
  "Inspection agent’s signature:": "Firma del agente de inspección:",
  "Truck driver’s signature:": "Firma del camionero:",
  "Executor’s signature:": "Firma del albacea:",
  "Executor’s name:": "Nombre del albacea:",
  "Truck Number": "Número de camión",
  "Truck Driver": "Conductor de camión",
  "Inspected by": "Inspeccionado por",
  "Outside Air Temperature": "Temperatura del aire exterior (C°)",
  "Is Partial": "Es parcial",
}
